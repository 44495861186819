import React, { useEffect, useLayoutEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import isMobile from "ismobilejs";
import { Helmet } from "react-helmet";

//
import "./layout.scss";
import Menu from "components/menu/menu";
import MouseCursor from "components/mouseCursor/mouseCursor";

interface layoutProps {
  children: any;
  pageContext: any;
}

const Layout: React.FC<layoutProps> = (props) => {
  useEffect(() => {
    console.log("layout load");
    // add mobile oder desktop class
    document.documentElement.classList.add(
      isMobile().any ? "is-mobile" : "is-desktop"
    );
  }, []);

  useLayoutEffect(() => {
    const handleResize = () => {
      document.documentElement.style.setProperty(
        "--win-height",
        `${window.innerHeight}px`
      );
    };
    //
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const meta = props.pageContext?.themeSettings;

  return (
    <>
      {props.pageContext && props.pageContext.themeSettings && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{meta.seoTitle || ""}</title>
          <meta name="description" content={meta.seoDescription || ""} />
          <meta property="og:description" content={meta.seoDescription || ""} />
          <meta property="og:title" content={meta.seoTitle || ""} />
          <meta
            property="og:image"
            content={meta.seoImage.mediaItemUrl || ""}
          />
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no"
          />
          <meta
            name="google-site-verification"
            content="POvdd-m6P8E8GoBMCIZz-aZL4bSpnB-OGe1WSbGPDbc"
          />
        </Helmet>
      )}

      <header>
        <Menu />
      </header>
      <MouseCursor />
      <main>{props.children}</main>
    </>
  );
};

export default Layout;
