import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import classNames from "classnames";
import { globalHistory } from '@reach/router'
//
import "./menu.scss";

interface menuProps {

}

const Menu: React.FC<menuProps> = (props) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const setDiagonal = () => {
      const diagonal = Math.sqrt(Math.pow(window.innerWidth, 2) + Math.pow(window.innerHeight, 2));
      document.documentElement.style.setProperty('--win-diagonal', `${diagonal.toFixed(0)}px`);
    }
    setDiagonal();
    window.addEventListener('resize', setDiagonal);
    return () => window.removeEventListener('resize', setDiagonal);
  }, []);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setOpen(false)
    })
  }, [setOpen]);

  useEffect(() => {
    open ? document.documentElement.classList.add('is-menu-open') : document.documentElement.classList.remove('is-menu-open') ;
  }, [open])

  const data = useStaticQuery(graphql`
    query MenuQuery {
      menu: allWpMenu(filter: { slug: { eq: "main-menu" } }) {
        nodes {
          menuItems {
            nodes {
              label
              path
              url
            }
          }
        }
      }
    }
  `);

  const menu = data.menu.nodes[0];

  return (
    <div className={classNames("menu", {"is-open": open})}>      
      {/* INNER */}
        <nav className="menu-nav">
          <ul>
            {menu.menuItems.nodes.map((item: any, key: any) => (
              <li className="menu-nav__item" key={key}>
                <Link to={item.path} activeClassName="is-active">{item.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      {/* TOGGLE */}
      <button className="menu-toggle" onClick={() => setOpen(!open)}>
        <div className="menu-toggle__inner" />
      </button>
    </div>
  );
}

export default Menu;