import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import isMobile from "ismobilejs";
//
import "./mouseCursor.scss";

interface mouseCursorProps {}
import Icon from "assets/icon-no-border.svg";

const MouseCursor: React.FC<mouseCursorProps> = (props) => {
  let cursorRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const [isHoverMenu, setIsHoverMenu] = useState<boolean>(false);
  const [isWhite, setIsWhite] = useState<boolean>(false);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setVisible(true);
      //
      
      //
      const cursor = cursorRef.current;
      if (!!cursor) {
        cursor.style.top = e.pageY - window.pageYOffset + "px";
        cursor.style.left = e.pageX + "px";
      }
      //
      const target = e.target as HTMLElement;
      const _isWhite = target.classList.contains("menu-toggle");
      setIsWhite(_isWhite);
      
      const isFocus =
        (target.tagName && !!target.tagName.match(/(^BUTTON$|^A$|^INPUT$|^LABEL$)/)) || false;
      setFocus(isFocus);
    };
    
    const handleMouseLeave = (e: MouseEvent) => {
      const target: HTMLElement = e.relatedTarget as HTMLElement;
      if (!target || target.nodeName === "HTML") {
        setVisible(false);
      }
    };

    !isMobile().any && window.addEventListener("mousemove", handleMouseMove);
    !isMobile().any && window.addEventListener("mouseout", handleMouseLeave);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseout", handleMouseLeave);
    };
  }, [visible, focus, isHoverMenu]);

  
  return (
    
    <div
      className={classNames("cursor", {
        "is-visible": visible,
        "is-focus": focus,
        "is-white": isWhite,
      })}
      ref={cursorRef}
    >
      {/* <div className="cursor__blue-dot" /> */}
      <Icon className="cursor__icon" />
    </div>
  );
};

export default MouseCursor;
